@defer (when dataLoaded) {
<div class="grid">
    <div class="col-12 md:col-6 xl:col-6">
      <p-panel header="{{ 'Utilisateurs disponibles' | transloco }}">
          <p-table 
              #tableAvailable
              [value]="availableUsers" 
              styleClass="p-datatable-gridlines p-datatable-striped"
              [paginator]="true"
              [rows]="15"
              [showCurrentPageReport]="false"
              currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
              >
              <ng-template pTemplate="header">
                  <tr>
                      <th pSortableColumn="clientGroup.name">{{ 'Groupe' | transloco }} <p-sortIcon field="clientGroup.name"></p-sortIcon></th>
                      <th pSortableColumn="lastName">{{ 'Nom' | transloco }} <p-sortIcon field="lastName"></p-sortIcon></th>
                      <th pSortableColumn="firstName">{{ 'Prénom' | transloco }} <p-sortIcon field="firstName"></p-sortIcon></th>
                      @if(canWrite()) {
                          <th></th>
                      }
                  </tr>
                  <tr>
                      <th>
                        <p-columnFilter field="clientGroup.id" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect
                              [ngModel]="value"
                              [options]="groups"
                              [placeholder]="'Toutes' | transloco"
                              (onChange)="filter($event.value)"
                              optionLabel="name"
                              optionValue="id"
                              class="liste-recherche-table"
                            >
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter
                          type="text"
                          field="lastName"
                          matchMode="contains"
                        ></p-columnFilter>
                      </th>
                      <th>
                          <p-columnFilter
                          type="text"
                          field="firstName"
                          matchMode="contains"
                        ></p-columnFilter>
                        
                      </th>
                      @if(canWrite()) {
                          <th></th>
                      }
                    </tr>
              </ng-template>
              <ng-template pTemplate="body" let-user>
                  <tr>
                      <td>{{ user.clientGroup.name }}</td>
                      <td>{{ user.lastName }}</td>
                      <td>{{ user.firstName }}</td>
                      @if(canWrite()) {
                          <td class="center-text pt-1 pb-1">
                              <a (click)="moveUser(user, true)" title="{{ 'Ajouter' | transloco}}">
                                  <i class="fa fa-angle-right"></i>
                              </a>
                          </td>
                      }
                  </tr>
              </ng-template>
          </p-table>
        </p-panel>
    </div>
    <div class=" col-12 md:col-6 xl:col-6">
        <p-panel header="{{ 'Utilisateurs affectés' | transloco }}">
          <p-table 
              #tableUsers
              [value]="affectedUsers" 
              styleClass="p-datatable-gridlines "
              [paginator]="true"
              [rows]="15"    
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}"
              >
              <ng-template pTemplate="header">
                  <tr>
                      @if(canWrite()) {
                          <th></th>
                      }
                      <th pSortableColumn="clientGroup.id">{{ 'Groupe' | transloco }} <p-sortIcon field="clientGroup.id"></p-sortIcon></th>
                      <th pSortableColumn="lastName">{{ 'Nom' | transloco }} <p-sortIcon field="lastName"></p-sortIcon></th>
                      <th pSortableColumn="firstName">{{ 'Prénom' | transloco }} <p-sortIcon field="firstName"></p-sortIcon></th>
                  </tr>
                  <tr>
                      @if(canWrite()) {
                          <th></th>
                      }
                      <th>
                        <p-columnFilter field="clientGroup.id" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect
                              [ngModel]="value"
                              [options]="groups"
                              [placeholder]="'Toutes' | transloco"
                              (onChange)="filter($event.value)"
                              optionLabel="name"
                              optionValue="id"
                              class="liste-recherche-table"
                            >
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter
                          type="text"
                          field="lastName"
                          matchMode="contains"
                        ></p-columnFilter>
                      </th>
                      <th>
                          <p-columnFilter
                          type="text"
                          field="firstName"
                          matchMode="contains"
                        ></p-columnFilter>
                        
                      </th>
                    </tr>
              </ng-template>
              <ng-template pTemplate="body" let-user>
                  <tr>
                      @if(canWrite()) {
                          <td class="center-text pt-1 pb-1">
                              <a (click)="moveUser(user, false)" title="{{ 'Retirer' | transloco}}">
                                  <i class="fa fa-angle-left"></i>
                              </a>
                          </td>
                      }
                      <td>{{ user.clientGroup?.name }}</td>
                      <td>{{ user.lastName }}</td>
                      <td>{{ user.firstName }}</td>
                  </tr>
              </ng-template>
          </p-table>
        </p-panel>
    </div>
</div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}