import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';

import { ClientGroup } from '../../../models/client-group';
import { ApiUsersService } from '../../../services/api/api-users.service';
import { User } from '../../../models/user';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-affect-users',
  templateUrl: './affect-users.component.html',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    MultiSelectModule,
    PanelModule,
    TableModule,
    TranslocoModule,
  ],
})
export class AffectUsersComponent implements OnInit, OnDestroy {
  @Input() getMethod!: (objectId: string) => Observable<any[]>;
  @Input() moveMethod!: (objectId: number, licenceId: number, add: boolean) => Observable<any>;

  allUsers: User[] = [];
  affectedUsers: User[] = [];
  availableUsers: User[] = [];
  groups: ClientGroup[] = [];

  @ViewChild('tableAvailable') availableRef!: Table;
  @ViewChild('tableUsers') userRef!: Table;

  constructor(
    private apiUsersService: ApiUsersService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  dataLoaded: boolean = false;
  usersSubscription: Subscription = new Subscription();
  objectId: string = ''; //l'objet pour qui on ajoute/retire les users : indicateurs satisfaction, licence,...

  ngOnInit(): void {
    this.objectId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.objectId != '') {
      this.apiUsersService
          .getUsers(true)
          .subscribe((data: User[]) => {
            this.allUsers = data;
            
            this.refreshUsers();
          });

      this.groups = this.authService.getUserGroups();

      this.refreshUsers();
    }
  }

  refreshUsers() {
    this.usersSubscription = this.getMethod(this.objectId)
      .subscribe((lstUsersAffected: number[]) => {

        this.affectedUsers = this.allUsers.filter(
          (oneUser: User) => lstUsersAffected.some(
            (affectedUserId: number) => affectedUserId === oneUser.id
          )
        );
        this.availableUsers = this.allUsers.filter(
          (oneUser: User) => !this.affectedUsers.some(
            (affectedUser: User) => affectedUser.id === oneUser.id
          )
        );

        this.dataLoaded = true;
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  moveUser(dataUser: User, addUser: boolean) {
    this.moveMethod(parseInt(this.objectId), dataUser.id, addUser)
      .subscribe((response: any) => {
        if (response) {
          
          if(addUser) {          
            const index = this.availableUsers.findIndex(
              (user) => user.id === dataUser.id
            );

            if (index !== -1) {
              const userToMove = this.availableUsers.splice(index, 1)[0];
              this.affectedUsers.push(userToMove);

              this.userRef.totalRecords += 1;
              this.availableRef.totalRecords -= 1;
            }
          } else {
            const index = this.affectedUsers.findIndex(
              (user) => user.id === dataUser.id
            );
            
            if (index !== -1) {
              const userToMove = this.affectedUsers.splice(index, 1)[0];
              this.availableUsers.push(userToMove);
              
              this.userRef.totalRecords -= 1;
              this.availableRef.totalRecords += 1;
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }
}
